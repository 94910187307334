import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './NotFound.css';

export default class NotFound extends Component {
  render() {
    return (
      <div>
        <div id='notfound'>
          <div className='notfound'>
            <h2>404 - Page not found</h2>
            <p>
              The page you are looking for might have been removed had its name
              changed or is temporarily unavailable.
            </p>
            <NavLink to='/'>
              <button className='btn btn-dark'>Go To Homepage</button>
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}
