import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import {
  Identity,
  TitleCustomerInformation,
  ButtonEndCall,
} from './CustomerCardStyle';
import { CustomerDataCRM } from '../../Pages/Entry/Entry';
import { Info, CallEnd } from '@material-ui/icons';
import { clearAllSession } from '../../../utils/session';

interface CustomerCardProps {
  customerData: CustomerDataCRM;
  gender: 'male' | 'female';
  endCall: () => void;
  updateGender: (gender: 'male' | 'female') => void;
}

const CustomerCard = ({
  customerData,
  gender,
  endCall,
  updateGender,
}: CustomerCardProps) => {
  return (
    <>
      <div className='d-flex align-items-center mb-3'>
        <Info className='mr-2 text-primary' />
        <TitleCustomerInformation>
          Customer Information
        </TitleCustomerInformation>
      </div>
      <Row>
        <Col md={6}>
          <Row>
            <Col md={4}>Customer ID</Col>
            <Col md={6}>
              <Identity>{customerData.customerId}</Identity>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={4}>Customer Name</Col>
            <Col md={6}>
              <Identity>{customerData.name} </Identity>
            </Col>
          </Row>
          <Row className='mt-3 align-items-center'>
            <Col md={4}>Jenis Kelamin</Col>
            <Col md={6}>
              <div className='d-flex'>
                <Form.Check
                  type='radio'
                  label='Laki-laki'
                  name='jenis-kelamin'
                  className='mr-3'
                  onChange={() => {
                    const tempGender: any = 'male';
                    updateGender(tempGender);
                  }}
                  checked={gender === 'male' ? true : false}
                />
                <Form.Check
                  type='radio'
                  label='Perempuan'
                  name='jenis-kelamin'
                  onChange={() => {
                    const tempGender: any = 'female';
                    updateGender(tempGender);
                  }}
                  checked={gender === 'female' ? true : false}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col md={6}>Minimum Enrollments</Col>
            <Col md={6}>
              <Identity>{customerData.minimumEnrollments} </Identity>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={6}>Enrolled Counts</Col>
            <Col md={6}>
              <Identity>{customerData.enrollments} </Identity>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <div className='d-flex justify-content-start'>
            <ButtonEndCall
              onClick={() => {
                endCall();
                clearAllSession();
              }}
            >
              <CallEnd style={{ color: 'white' }} />
            </ButtonEndCall>
          </div>
          <div className='d-flex justify-content-start mt-2'>
            <div style={{ fontWeight: 'bold' }}>End Call</div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CustomerCard;
