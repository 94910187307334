import React from 'react';
import Modal from 'react-modal';

const customStyles: any = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '30%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(71, 71, 71, 0.75)',
  },
};

interface ModalCRMProps {
  isOpen: boolean;
  children: React.ReactNode;
}

const ModalCRM = ({ isOpen, children }: ModalCRMProps) => {
  return (
    <>
      <Modal isOpen={isOpen} style={customStyles}>
        {children}
      </Modal>
    </>
  );
};

export default ModalCRM;
