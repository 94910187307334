import React, { useState, useContext } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { AuthUser } from '../../../Model/authentication';
import { useForm } from '../../../utils/useForm';
import { AuthContext } from '../../../utils/Authentication';
import { ReactSVG } from 'react-svg';

import {
  FixedContainer,
  CustomButton,
  LeftContainer,
  TitleBrand,
  Welcome,
  SignIn,
  CustomImage,
  CallCenter,
  Disclaimer,
  // ClickableDiv,
} from './LoginStyle';
import WelcomeImage from '../../../Assets/enroll.png';
import IconCS from '../../../Assets/Group 5268.svg';

interface LoginState {
  username: string;
  password: string;
}

const initialState: LoginState = {
  username: '',
  password: '',
};

const Login = ({ history }: any) => {
  const auth = useContext(AuthContext);
  const [login, setLogin] = useForm(initialState);
  const [error, setError] = useState('');

  const onClickLogin = async () => {
    if (login.username === '' || login.password === '') {
      setError('Fill username & password');
      return;
    }

    const result: any = await AuthUser(login);
    if (result.status === true) {
      const { func } = auth;
      func.changeAuthToTrue({
        username: login.username,
        token: result.body.access_token,
      });
      if (login.username === 'admin') {
        history.push('/admin/home');
      } else {
        history.push('/crm');
      }
    } else {
      setError(result.body.message);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onClickLogin();
    }
  };

  // const handleCheckbox = (event: any) => {};

  return (
    <FixedContainer>
      <Row>
        <Col md={7}>
          <LeftContainer>
            <div className='d-flex justify-content-center'>
              <TitleBrand>CRM</TitleBrand>
            </div>
            <div className='d-flex justify-content-center'>
              <Welcome>Welcome Back</Welcome>
            </div>
            <div className='d-flex justify-content-center'>
              <Disclaimer>
                For Demo Purpose to Display Capability of Prosa Voice ID
              </Disclaimer>
            </div>
            <div className='d-flex justify-content-center'>
              <CustomImage
                src={WelcomeImage}
                alt='image-welcome'
                className='img-fluid'
              />
            </div>
          </LeftContainer>
        </Col>
        <Col md={5}>
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ width: '100%', height: '100%' }}
          >
            <div style={{ width: '100%' }}>
              <div className='d-flex align-items-center justify-content-center'>
                <ReactSVG src={IconCS} />
              </div>
              <div className='d-flex justify-content-center mt-4'>
                <SignIn>Sign in to your account</SignIn>
              </div>
              <div
                style={{ paddingLeft: '10rem', paddingRight: '10rem' }}
                className='mt-4'
              >
                <div
                  className='mb-2'
                  style={{ color: 'rgba(80, 80, 80, 0.8)' }}
                >
                  Username
                </div>
                <input
                  placeholder='Username'
                  value={login.username}
                  onChange={setLogin}
                  onKeyPress={handleKeyPress}
                  name='username'
                  type='text'
                  className='form-control border-light shadow-sm'
                />
                <div
                  className='mt-3 mb-2'
                  style={{ color: 'rgba(80, 80, 80, 0.8)' }}
                >
                  Password
                </div>
                <input
                  placeholder='Password'
                  value={login.password}
                  onChange={setLogin}
                  onKeyPress={handleKeyPress}
                  name='password'
                  type='password'
                  className='form-control border-light shadow-sm'
                />
                {/* <div className='d-flex justify-content-between mt-3 align-items-center'>
                  <ClickableDiv
                    onClick={handleCheckbox}
                    className='d-flex align-items-center'
                  >
                    <input type='checkbox' className='mr-2' /> Remember me
                  </ClickableDiv>
                  <ClickableDiv>Forget your password?</ClickableDiv>
                </div> */}
                <CustomButton
                  onClick={onClickLogin}
                  className='mt-4 rounded form-control shadow-sm'
                >
                  Login
                </CustomButton>
                {error !== '' ? (
                  <Alert
                    variant='danger'
                    className='mt-4 border-light shadow-sm'
                  >
                    {error}
                  </Alert>
                ) : (
                  ''
                )}
                <div className='d-flex mt-3'>
                  Don`&apos;`t have an account ?{' '}
                  <CallCenter className='ml-2' style={{ fontWeight: 500 }}>
                    Call Center
                  </CallCenter>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </FixedContainer>
  );
};

export default Login;
