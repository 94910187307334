import styled from 'styled-components';

export const EnrollButton = styled.button`
  background-color: #f25822;
  border: none;
  color: white;
  border-radius: 4px;
  padding: 6px 14px 6px 10px;

  ${this}:hover {
    cursor: pointer;
    background-color: #96300c;
  }

  ${this}:disabled {
    cursor: default;
    background-color: #cecece;
  }
`;

export const NumberHighlight = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #000000;
`;

export const TitleConfirmEnrollment = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #373737;
`;

export const ConfirmEnrollment = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #000000;
`;
