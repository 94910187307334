import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../../utils/Authentication';
import Container from '../../Components/Container/Container';
import { Alert } from 'react-bootstrap';
import {
  getAllQuestions,
  QuestionInterface,
  deleteQuestion,
} from '../../../Model/question';
import { Table } from '../../Components/Table/Table';
import { DeleteIcon } from '../Customer/CustomerStyle';
import { Delete } from '@material-ui/icons';

interface QuestionData extends QuestionInterface {
  subRows?: QuestionData[];
}

const Question = () => {
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const authentication = useContext(AuthContext);
  const { auth } = authentication;
  const columns = [
    {
      Header: 'User',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'Question',
          accessor: 'question',
        },
        {
          Header: 'Answer',
          accessor: 'answer',
        },
        {
          Header: 'Call ID',
          accessor: 'call_id',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'Template ID',
          accessor: 'template_id',
        },
        {
          Header: 'Action',
          accessor: 'id',
          Cell: ({ row }: any) => {
            return (
              <div className='d-flex'>
                <DeleteIcon
                  onClick={() => {
                    removeQuestion(row.original.id);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Delete />
                </DeleteIcon>
              </div>
            );
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchQuestionHooks = async () => {
      const result: any = await getAllQuestions(auth.data.token);
      if (result.status === true) {
        setData(result.body);
      } else {
        setError(result.body.message);
      }
    };
    fetchQuestionHooks();
  }, [auth]);

  const fetchQuestion = async () => {
    const result: any = await getAllQuestions(auth.data.token);
    if (result.status === true) {
      setData(result.body);
    } else {
      setError(result.body.message);
    }
  };

  const reFetchOrRaiseError = (result: any) => {
    if (result.status === true) {
      fetchQuestion();
    } else {
      setError(result.body.message);
    }
  };

  const removeQuestion = async (id: number) => {
    const result: any = await deleteQuestion(auth.data.token, id);
    reFetchOrRaiseError(result);
  };

  return (
    <Container title={'Log Questions'}>
      {error !== '' ? (
        <Alert className='mt-3' variant='warning'>
          {error}
        </Alert>
      ) : (
        <>
          <Table<QuestionData>
            name={'templateTable'}
            columns={columns}
            data={data}
          />
        </>
      )}{' '}
    </Container>
  );
};

export default Question;
