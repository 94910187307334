import React, { useState, useEffect, useContext } from 'react';
import { patchQuestion } from '../../../Model/question';
import { Col, Row } from 'react-bootstrap';
import HelpIcon from '@material-ui/icons/Help';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { HighlightOff } from '@material-ui/icons';
import { QuestionInterface } from '../../../Model/question';
import { AuthContext } from '../../../utils/Authentication';
import {
  IconContainer,
  LegitButton,
  QuestionTitle,
  FraudButton,
  LabelInfo,
  TrueNumber,
  FalseNumber,
} from './TableQuestionStyle';
import { saveQuestions } from '../../../utils/session';
import { CheckCircleOutline } from '@material-ui/icons';
import CustomTable from '../Table/CustomTable';

interface TableQuestionProps {
  questions: QuestionInterface[];
  raiseErrorMessage: (msg: string) => void;
  setQuestions: (questions: QuestionInterface[]) => void;
  setModalStatusFraud: (fraudStatus: any) => void;
  children: React.ReactNode;
  isAlreadySubmit: boolean;
}

interface CountInterface {
  true: number;
  false: number;
  missed: number;
}

const InitialStateCount: CountInterface = {
  true: 0,
  false: 0,
  missed: 0,
};

const TableQuestion = ({
  questions,
  raiseErrorMessage,
  setQuestions,
  setModalStatusFraud,
  children,
  isAlreadySubmit,
}: TableQuestionProps) => {
  const authentication = useContext(AuthContext);
  const [, updateState]: any = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [count, setCount] = useState(InitialStateCount);

  useEffect(() => {
    const tempQuestion: QuestionInterface[] = questions;
    const tempCount = {
      true: 0,
      false: 0,
      missed: 0,
    };
    tempQuestion.forEach((element: QuestionInterface) => {
      if (element.status === true) {
        tempCount.true = tempCount.true + 1;
      } else if (element.status === false) {
        tempCount.false = tempCount.false + 1;
      } else {
        tempCount.missed = tempCount.missed + 1;
      }
    });
    setCount(tempCount);
  }, [questions]);

  const changeStatusQuestion = async (id: number, status: boolean) => {
    const result: any = await patchQuestion(
      authentication.auth.data.token,
      id,
      status
    );
    if (result.status === false) {
      raiseErrorMessage(result.body.message);
    } else {
      const tempQuestion: QuestionInterface[] = questions;
      tempQuestion.forEach((element: QuestionInterface) => {
        if (element.id === id) {
          element.status = status;
        }
      });
      const tempCount = {
        true: 0,
        false: 0,
        missed: 0,
      };
      tempQuestion.forEach((element: QuestionInterface) => {
        if (element.status === true) {
          tempCount.true = tempCount.true + 1;
        } else if (element.status === false) {
          tempCount.false = tempCount.false + 1;
        } else {
          tempCount.missed = tempCount.missed + 1;
        }
      });
      setCount(tempCount);
      setQuestions(tempQuestion);
      saveQuestions(tempQuestion);
      forceUpdate();
    }
  };

  const column: any = [
    {
      Header: 'Question',
      accessor: 'question', // accessor is the "key" in the data
    },
    {
      Header: 'Answer',
      accessor: 'answer',
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: ({ row }: any) => {
        const data = row.original;
        return (
          <div className='d-flex align-items-center'>
            {data.status === false ? (
              <IconContainer
                title={'False'}
                onClick={() => {
                  changeStatusQuestion(data.id, false);
                }}
              >
                <HighlightOff
                  style={{ color: '#f55656', fontSize: 30 }}
                  className='mr-3'
                />
              </IconContainer>
            ) : (
              <IconContainer
                title={'False'}
                onClick={() => {
                  changeStatusQuestion(data.id, false);
                }}
              >
                <CancelIcon
                  style={{ color: '#CECECE', fontSize: 30 }}
                  className='mr-3'
                />
              </IconContainer>
            )}

            {data.status === true ? (
              <IconContainer
                title={'True'}
                onClick={() => {
                  changeStatusQuestion(data.id, true);
                }}
              >
                <CheckCircleOutline
                  style={{ color: '#4caf50', fontSize: 30 }}
                />
              </IconContainer>
            ) : (
              <IconContainer
                title={'True'}
                onClick={() => {
                  changeStatusQuestion(data.id, true);
                }}
              >
                <CheckCircleIcon style={{ color: '#CECECE', fontSize: 30 }} />
              </IconContainer>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className='d-flex align-items-center mb-3'>
        <HelpIcon style={{ color: '#F25822' }} className='mr-2' />
        <QuestionTitle> Questions </QuestionTitle>
      </div>

      <CustomTable data={questions} column={column} />

      <div className='mt-3 d-flex justify-content-center align-items-center'></div>
      <Row className='mt-4 align-items-center'>
        <Col md={9}>{children}</Col>
        <Col md={2}>
          <Row>
            <Col md={6} className='d-flex justify-content-center'>
              <div className='text-center'>
                <FalseNumber>{count.false}</FalseNumber>
                <LabelInfo>FALSE</LabelInfo>
              </div>
            </Col>
            <Col md={6} className='d-flex justify-content-center'>
              <div className='text-center'>
                <TrueNumber>{count.true}</TrueNumber>
                <LabelInfo>TRUE</LabelInfo>
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={6} className='d-flex justify-content-center'>
              <FraudButton
                onClick={() => {
                  setModalStatusFraud({ status: true, legit: 'not_legit' });
                }}
                disabled={isAlreadySubmit}
              >
                Fraud
              </FraudButton>
            </Col>
            <Col md={6} className='d-flex justify-content-center'>
              <LegitButton
                onClick={() => {
                  setModalStatusFraud({ status: true, legit: 'legit' });
                }}
                disabled={isAlreadySubmit}
              >
                Legit
              </LegitButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default TableQuestion;
