import { CONFIG_BACKEND_URL } from '../utils/conf';

export interface Content {
  id: number;
  created_at: string;
  path: string;
  method: string;
  response: string;
  server: string;
  client: string;
  username: string;
  message: string;
}

export interface TrailInterface {
  page_number: number;
  page_size: number;
  total_pages: number;
  has_prev: boolean;
  has_next: boolean;
  content: Content[];
}

export const getTrail = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(
    `${CONFIG_BACKEND_URL}/audit/access_logs?page=0&page_size=1000000&sort=~created_at`,
    requestOptions
  )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};
