import { CONFIG_BACKEND_URL } from '../utils/conf';

export interface CustomerInterface {
  customerId: string;
  gender: string;
  enrollments: number;
  fullName: string;
  address: string;
  dateOfBirth: string;
  email: string;
}

export const getAllCustomer = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/customers/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const deleteCustomer = async (token: string, id: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/customers/${id}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const saveCustomer = async (
  token: string,
  customer: CustomerInterface
) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    customer_id: customer.customerId,
    gender: customer.gender,
    full_name: customer.fullName,
    address: customer.address,
    date_of_birth: customer.dateOfBirth,
    email: customer.email,
  });
  const requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/customers/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const updateCustomer = async (
  token: string,
  customer: CustomerInterface
) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    gender: customer.gender,
    full_name: customer.fullName,
    address: customer.address,
    date_of_birth: customer.dateOfBirth,
    email: customer.email,
  });
  const requestOptions: any = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(
    `${CONFIG_BACKEND_URL}/customers/${customer.customerId}`,
    requestOptions
  )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};
