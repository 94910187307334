import React, { useEffect, useContext, useState } from 'react';

import { AuthContext } from '../../../utils/Authentication';
import Enrollment from '../Enrollment/Enrollment';
import InputCustomer from '../InputCustomer/InputCustomer';
import Landing from '../Landing/Landing';
import { AgentInterface, getMyInfo } from '../../../Model/agent';
import { getCall, saveCall } from '../../../utils/session';
import { CONFIG_WS_URL } from '../../../utils/conf';

const InitialAgent: AgentInterface = {
  username: '',
  active: false,
  name: '',
  agentId: '',
  extension: '',
};

export interface CRMStateInterface {
  page: 'wait' | 'input' | 'enroll';
  idCustomer: string;
  errorMsg: string;
  socket: null | WebSocket;
  verified?: null | boolean;
  score: number;
  voiced: number;
  duration: number;
  callId: string;
  callDurationInterval: any;
  statusCall: 'Ongoing' | 'Call Ended';
}

const InitialCRMState: CRMStateInterface = {
  page: 'wait',
  idCustomer: '',
  errorMsg: '',
  socket: null,
  verified: undefined,
  score: 0,
  voiced: 0,
  duration: 0,
  callId: '',
  callDurationInterval: null,
  statusCall: 'Ongoing',
};

export interface CustomerDataCRM {
  isVerifying: boolean;
  gender: 'male' | 'female';
  customerId: string;
  enrollments: number;
  minimumEnrollments: number;
  name: string;
  minVoicedDuration: number;
  callDuration: number;
}

const InitialCustomerDataCRM: CustomerDataCRM = {
  isVerifying: false,
  gender: 'female',
  customerId: '',
  enrollments: 0,
  minimumEnrollments: 0,
  name: '',
  minVoicedDuration: 0,
  callDuration: 0,
};

const Entry = () => {
  const authentication = useContext(AuthContext);
  const [crmState, setCrmState] = useState(InitialCRMState);
  const [agent, setAgent] = useState(InitialAgent);
  const [customerData, setCustomerData] = useState(InitialCustomerDataCRM);

  useEffect(() => {
    const fetchAgentInformation = async () => {
      const result: any = await getMyInfo(authentication.auth.data.token);
      const { body } = result;
      setAgent({
        username: body.username,
        active: body.active,
        name: body.name,
        agentId: body.agent_id,
        extension: body.extension,
      });
    };
    fetchAgentInformation();
    const callIdFromStorage = getCall();
    if (callIdFromStorage !== null) {
      setCrmState((c) => ({
        ...c,
        callId: callIdFromStorage,
      }));
    }

    const newSocket = new WebSocket(`${CONFIG_WS_URL}/dashboard/ws`);

    let page: 'wait' | 'input' | 'enroll' = 'wait';
    newSocket.onopen = () => {
      newSocket.send(
        JSON.stringify({
          code: 200,
          data: {
            auth: authentication.auth.data.token,
          },
        })
      );
    };

    newSocket.onclose = () => {
      console.log('Connection closed');
    };

    newSocket.onmessage = (evt) => {
      const data = JSON.parse(evt.data);
      const code = data.code;
      if (code === 101) {
        saveCall(data.data.call_id);
        setCrmState((c) => ({
          ...c,
          page: 'input',
          callId: data.data.call_id,
        }));
        page = 'input';
      } else if (code === 102) {
        raiseErrorMessage('Customer ID Salah');
      } else if (code === 103) {
        const customer: any = data.data;
        setCrmState((c) => ({
          ...c,
          page: 'enroll',
          duration: customer.call_duration,
          statusCall: 'Ongoing',
        }));
        page = 'enroll';
        setCustomerData({
          isVerifying: customer.is_verifying,
          enrollments: customer.enrollments,
          gender: customer.gender,
          customerId: customer.customer_id,
          minimumEnrollments: customer.minimum_enrollments,
          name: customer.customer_name,
          minVoicedDuration: customer.minimum_voiced_duration,
          callDuration: customer.call_duration,
        });
      } else if (code === 105) {
        raiseErrorMessage('Gagal Enrollment');
      } else if (code === 107) {
        console.log('Berhasil ganti jenis kelamin');
      } else if (code === 108) {
        raiseErrorMessage('Gagal mengubah jenis kelamin');
      } else if (code === 109) {
        raiseErrorMessage('Gagal mendaftarkan ke biometric server');
      } else if (code === 111) {
        raiseErrorMessage('Gagal merubah status telepon');
      } else if (code === 106) {
        console.log(data);
        setCrmState((c) => ({
          ...c,
          verified: data.data.verified,
          score: data.data.score,
          voiced: data.data.voiced,
        }));
      } else if (code === 199) {
        if (page === 'input') {
          const initState = InitialCRMState;
          initState.socket = newSocket;
          setCrmState(initState);
        } else if (page === 'enroll') {
          setCrmState((c) => ({
            ...c,
            statusCall: 'Call Ended',
          }));
        }
      }
    };

    newSocket.onerror = () => {
      raiseErrorMessage('Error Connection Web Socket');
    };

    setCrmState((c) => ({ ...c, socket: newSocket }));
  }, [authentication]);

  const verifyCustomerID = (customerID: string) => {
    if (crmState.socket === null) return;
    crmState.socket.send(
      JSON.stringify({
        code: 202,
        data: {
          customer_id: customerID,
        },
      })
    );
  };

  const changeGender = (gender: string) => {
    if (crmState.socket === null) return;
    crmState.socket.send(
      JSON.stringify({
        code: 204,
        data: {
          gender: gender,
        },
      })
    );
  };

  const endCall = () => {
    if (crmState.socket === null) return;
    crmState.socket.send(
      JSON.stringify({
        code: 206,
      })
    );
    const initState = InitialCRMState;
    initState.socket = crmState.socket;
    setCrmState(initState);
  };

  const raiseErrorMessage = (msg: string) => {
    setCrmState((c) => ({
      ...c,
      errorMsg: msg,
    }));

    setTimeout(() => {
      setCrmState((c) => ({
        ...c,
        errorMsg: '',
      }));
    }, 3000);
  };

  if (crmState.page === 'wait') {
    return <Landing agent={agent} />;
  } else if (crmState.page === 'input') {
    return (
      <InputCustomer
        agent={agent}
        errorMsg={crmState.errorMsg}
        verifyCustomerID={verifyCustomerID}
      />
    );
  } else if (crmState.page === 'enroll') {
    return (
      <Enrollment
        crmState={crmState}
        customerData={customerData}
        agent={agent}
        changeGender={changeGender}
        errorMsg={crmState.errorMsg}
        raiseErrorMessage={raiseErrorMessage}
        endCall={endCall}
        setCustomerData={setCustomerData}
      />
    );
  }

  return <></>;
};

export default Entry;
