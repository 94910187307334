import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Authentication from './utils/Authentication';
import { ProtectedRoute } from './utils/ProtectedRoute';

import Login from './Global/Components/Login/Login';
import NotFound from './Global/Components/NotFound/NotFound';
import HomePage from './Admin/Pages/HomePage/HomePage';
import Customer from './Admin/Pages/Customer/Customer';
import User from './Admin/Pages/User/User';
import Agent from './Admin/Pages/Agent/Agent';
import Entry from './CRM/Pages/Entry/Entry';
import Call from './Admin/Pages/Call/Call';
import Templates from './Admin/Pages/Templates/Templates';
import Question from './Admin/Pages/Question/Question';
import Trail from './Admin/Pages/Trail/Trail';

interface RouteData {
  id: number;
  path: string;
  component: () => JSX.Element;
  onlyAdmin: boolean;
}

// List of route that protected by ProtectedRoute
const arrayProtectedRoute: RouteData[] = [
  {
    id: 1,
    path: '/admin/home',
    component: HomePage,
    onlyAdmin: true,
  },
  {
    id: 2,
    path: '/admin/customer',
    component: Customer,
    onlyAdmin: true,
  },
  {
    id: 3,
    path: '/admin/user',
    component: User,
    onlyAdmin: true,
  },
  {
    id: 4,
    path: '/admin/agent',
    component: Agent,
    onlyAdmin: true,
  },
  {
    id: 5,
    path: '/admin/call',
    component: Call,
    onlyAdmin: true,
  },
  {
    id: 6,
    path: '/admin/template',
    component: Templates,
    onlyAdmin: true,
  },
  {
    id: 7,
    path: '/admin/question',
    component: Question,
    onlyAdmin: true,
  },
  {
    id: 7,
    path: '/admin/trail',
    component: Trail,
    onlyAdmin: true,
  },
  {
    id: 101,
    path: '/crm',
    component: Entry,
    onlyAdmin: false,
  },
];

function App() {
  return (
    <Router>
      <Authentication>
        <Switch>
          <Route exact path='/' component={Login}></Route>
          {arrayProtectedRoute.map((data: RouteData) => {
            return (
              <ProtectedRoute
                key={data.id}
                exact
                path={data.path}
                component={data.component}
                onlyAdmin={data.onlyAdmin}
              ></ProtectedRoute>
            );
          })}
          {/* <ProtectedRoute path='/home' component={Home}></ProtectedRoute> */}

          <Route path='*' component={() => <NotFound />} />
        </Switch>
      </Authentication>
    </Router>
  );
}

export default App;
