import React from 'react';
import { Content } from './ContainerStyle';
import SideBar from '../../Components/SideBar/SideBar';

interface ContainerProps {
  children: React.ReactNode;
  title: string;
}

const Container = ({ children, title }: ContainerProps) => {
  return (
    <>
      <SideBar />
      <Content>
        <div className='d-flex align-items-center'>
          <h3 className='ml-2'>{title}</h3>
        </div>
        {children}
      </Content>
    </>
  );
};

export default Container;
