import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10rem;
`;

export const Information = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #6b6767;
`;
