import styled from 'styled-components';

export const TimeContainer = styled.div`
  background-color: #fafafa;
  margin: 10rem;
  padding: 5rem;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeCircle = styled.div`
  background-color: #c1c1c1;
  padding: 1.5rem;
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  background-color: rgba(128, 128, 128, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CardWaiting = styled.div`
  background-color: white;
  position: absolute;
  width: 25vw;
  height: 350px;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const CounterStyle = styled.div`
  font-weight: 500;
  font-size: 40px;
  color: #3e3e3e;
`;

export const WaitingStyle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #6b6767;
`;

export const FirstGreeting = styled.span`
  font-weight: 500;
  font-size: 40px;
  color: #858585;
`;

export const SecondGreeting = styled.span`
  font-weight: 500;
  font-size: 40px;
  color: #f49c76;
`;

export const Waiting = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(72, 72, 72, 0.82);
`;

export const LandingCustomImage = styled.img`
  position: absolute;
  width: 70%;
  margin-top: 40vh;
`;
