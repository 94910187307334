import React, { useState, useEffect } from 'react';

import {
  FirstGreeting,
  SecondGreeting,
  Waiting,
  LandingCustomImage,
} from '../Landing/LandingStyle';
import { Information, Wrapper } from './InputCustomerStyle';
import { CustomButton } from '../../../Global/Components/Login/LoginStyle';
import InputCustomerImage from '../../../Assets/Input.png';
import ContainerCRM from '../../Components/ContainerCRM/ContainerCRM';
import { Alert } from 'react-bootstrap';
import { AgentInterface } from '../../../Model/agent';
import ClipLoader from 'react-spinners/ClipLoader';

interface LeftContentProps {
  name: string;
}

const LeftContent = ({ name }: LeftContentProps) => {
  return (
    <>
      <div>
        <FirstGreeting>Halo, </FirstGreeting>
        <SecondGreeting>{name}</SecondGreeting>
      </div>
      <Waiting className='mt-2'>Please input customer ID</Waiting>
      <div className='d-flex justify-content-center'>
        <LandingCustomImage
          src={InputCustomerImage}
          alt='pop-img'
          className='img-fluid'
        />
      </div>
    </>
  );
};

interface InputCustomerProps {
  agent: AgentInterface;
  errorMsg: string;
  verifyCustomerID: (customerID: string) => void;
}

const InputCustomer = ({
  agent,
  errorMsg,
  verifyCustomerID,
}: InputCustomerProps) => {
  const [customerId, setCustomerId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (errorMsg !== '') {
      setIsLoading(false);
    }
  }, [errorMsg]);

  const submitCustomerID = () => {
    setIsLoading(true);
    verifyCustomerID(customerId);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      submitCustomerID();
    }
  };

  return (
    <ContainerCRM
      leftContent={<LeftContent name={agent.name} />}
      activeTopBar={2}
    >
      <Wrapper>
        <Information>Input customer ID Here</Information>
        <div className='mt-4 w-25'>
          <div className='mb-2'>Customer ID</div>
          <input
            type='text'
            className='form-control'
            value={customerId}
            onChange={(e) => {
              setCustomerId(e.target.value);
            }}
            onKeyPress={handleKeyPress}
            placeholder='Customer ID'
          />
          {errorMsg !== '' ? (
            <Alert className='mt-3 border-0 shadow-sm' variant={'danger'}>
              {errorMsg}
            </Alert>
          ) : (
            ''
          )}
          <CustomButton
            onClick={submitCustomerID}
            className='mt-4 rounded form-control shadow-sm'
            disabled={isLoading}
          >
            {isLoading ? (
              <ClipLoader loading={isLoading} size={24} color={'#f25822'} />
            ) : (
              'Submit'
            )}
          </CustomButton>
        </div>
      </Wrapper>
    </ContainerCRM>
  );
};

export default InputCustomer;
