// Component to store global state to provide necessary data for children

import React, { createContext, useState } from 'react';
import { saveCredential, getCredential } from './session';
import { LogoutUser } from '../Model/authentication';
import { CONFIG_AUTH } from './conf';

interface Data {
  username: string;
  token: string;
}
export interface UserData {
  isAuth: boolean;
  data: Data;
}

interface UserFunction {
  changeAuthToFalse: () => void;
  changeAuthToTrue: (data: Data) => void;
}

interface AuthenticationState {
  auth: UserData;
  func: UserFunction;
}

interface AuthenticationProps {
  children: React.ReactNode;
}

const defaultAuth: AuthenticationState = {
  auth: {
    isAuth: CONFIG_AUTH,
    data: {
      username: '',
      token: '',
    },
  },
  func: {
    changeAuthToFalse: () => undefined,
    changeAuthToTrue: () => undefined,
  },
};

export const AuthContext = createContext(defaultAuth);

const Authentication = ({ children }: AuthenticationProps) => {
  const defaultAuth: AuthenticationState = {
    auth: {
      isAuth: false,
      data: {
        username: '',
        token: '',
      },
    },
    func: {
      changeAuthToFalse: () => undefined,
      changeAuthToTrue: () => undefined,
    },
  };
  defaultAuth.auth = getCredential();
  const [auth, setAuth] = useState(defaultAuth);

  const changeAuthToFalse = () => {
    LogoutUser(auth.auth.data.token);
    setAuth(defaultAuth);
  };

  const changeAuthToTrue = (data: Data) => {
    const newAuth = defaultAuth;
    newAuth.auth.isAuth = true;
    newAuth.auth.data = data;
    saveCredential(newAuth.auth);
    setAuth(newAuth);
  };
  return (
    <AuthContext.Provider
      value={{
        auth: auth.auth,
        func: {
          changeAuthToFalse: changeAuthToFalse,
          changeAuthToTrue: changeAuthToTrue,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default Authentication;
