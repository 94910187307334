import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../../utils/Authentication';
import {
  deleteTemplate,
  getAllTemplates,
  saveTemplate,
  TemplateInterface,
  updateTemplate,
} from '../../../Model/template';
import Container from '../../Components/Container/Container';
import { Table } from '../../Components/Table/Table';
import { Alert } from 'react-bootstrap';
import { EditIcon, DeleteIcon } from '../Customer/CustomerStyle';
import { Edit, Delete } from '@material-ui/icons';
import { ReactModal, ReactModalProps } from '../../Components/Modal/ReactModal';
import { useForm } from '../../../utils/useForm';

interface TemplateData extends TemplateInterface {
  subRows?: TemplateData[];
}

const ReactModalInitialProps: ReactModalProps = {
  isOpen: false,
  children: <></>,
  title: '',
  actionLabel: '',
  actionColor: '',
  resetAll: () => undefined,
};

export interface TemplateForm {
  id: number;
  question: string;
  answer: string;
}

const InitialFormData: TemplateForm = {
  id: 0,
  question: '',
  answer: '',
};

const Templates = () => {
  const authentication = useContext(AuthContext);
  const { auth } = authentication;
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [formData, setFormData, overrideData] = useForm(InitialFormData);
  ReactModalInitialProps.resetAll = () => {
    setModalConfig(ReactModalInitialProps);
  };
  const [modalConfig, setModalConfig] = useState(ReactModalInitialProps);
  const columns = [
    {
      Header: 'Customer',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'Question',
          accessor: 'question',
        },
        {
          Header: 'Answer',
          accessor: 'answer',
        },
        {
          Header: 'Action',
          accessor: 'id',
          Cell: ({ row }: any) => {
            return (
              <div className='d-flex'>
                <EditIcon
                  onClick={() => {
                    openModalEdit(row.original);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Edit />
                </EditIcon>
                <DeleteIcon
                  onClick={() => {
                    removeTemplate(row.original.id);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Delete />
                </DeleteIcon>
              </div>
            );
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchTemplatesEffect = async () => {
      const result: any = await getAllTemplates(auth.data.token);
      if (result.status === true) {
        setData(result.body);
      } else {
        setError(result.body.message);
      }
    };
    fetchTemplatesEffect();
  }, [auth]);

  const fetchTemplates = async () => {
    const result: any = await getAllTemplates(auth.data.token);
    if (result.status === true) {
      setData(result.body);
    } else {
      setError(result.body.message);
    }
  };

  const reFetchOrRaiseError = (result: any) => {
    if (result.status === true) {
      fetchTemplates();
    } else {
      setError(result.body.message);
    }
  };

  const openModalAdd = () => {
    overrideData(InitialFormData);
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Add Template',
      actionLabel: 'Add',
      actionColor: '#77dd77',
    });
  };

  const openModalEdit = (data: any) => {
    overrideData({
      id: data.id,
      answer: data.answer,
      question: data.question,
    });
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Edit Template',
      actionLabel: 'Edit',
      actionColor: '#ff964f',
    });
  };

  const removeTemplate = async (id: number) => {
    const result: any = await deleteTemplate(auth.data.token, id);
    reFetchOrRaiseError(result);
  };

  const addTemplate = async () => {
    const result: any = await saveTemplate(auth.data.token, formData);
    reFetchOrRaiseError(result);
  };

  const editTemplate = async () => {
    const result: any = await updateTemplate(auth.data.token, formData);
    console.log(result);
    reFetchOrRaiseError(result);
  };

  return (
    <Container title={'List Questions'}>
      {error !== '' ? (
        <Alert className='mt-3' variant='warning'>
          {error}
        </Alert>
      ) : (
        <>
          <div className='d-flex justify-content-end'>
            <button onClick={openModalAdd} className='btn btn-info'>
              Add Questions
            </button>
          </div>
          <Table<TemplateData>
            name={'questionTable'}
            columns={columns}
            data={data}
          />
        </>
      )}{' '}
      {/* Modal */}
      <ReactModal
        {...modalConfig}
        onClickAdd={addTemplate}
        onClickEdit={editTemplate}
      >
        <p>Question</p>
        <input
          type='text'
          name='question'
          value={formData.question}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Answer</p>
        <input
          type='text'
          name='answer'
          value={formData.answer}
          onChange={setFormData}
          className='form-control'
        />
        <hr />
      </ReactModal>
    </Container>
  );
};

export default Templates;
