import styled from 'styled-components';

export const BackButton = styled.button`
  background-color: #17a2b8;
  color: white;
  padding: 8px 10px;
  border: none;
  border-radius: 4px;
  margin-right: 6px;

  ${this}:hover {
    cursor: pointer;
    background-color: #007bff;
  }

  ${this}:disabled {
    background-color: #cecece;
    cursor: default;
  }
`;
