import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../utils/Authentication';
import Container from '../../Components/Container/Container';
import {
  getAllUser,
  saveUser,
  UserInterface,
  changePassword,
  deleteUser,
} from '../../../Model/user';
import { EditIcon, DeleteIcon } from '../Customer/CustomerStyle';
import { Edit, Delete } from '@material-ui/icons';
import { Alert } from 'react-bootstrap';
import { Table } from '../../Components/Table/Table';
import { ReactModal, ReactModalProps } from '../../Components/Modal/ReactModal';
import { useForm } from '../../../utils/useForm';

interface UserData extends UserInterface {
  subRows?: UserData[];
}

const ReactModalInitialProps: ReactModalProps = {
  isOpen: false,
  children: <></>,
  title: '',
  actionLabel: '',
  actionColor: '',
  resetAll: () => undefined,
};

export interface UserForm {
  username: string;
  password: string;
  agentId: string;
}

const InitialFormData: UserForm = {
  username: '',
  password: '',
  agentId: '',
};

const User = () => {
  const authentication = useContext(AuthContext);
  const { auth } = authentication;
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const [formData, setFormData, overrideData] = useForm(InitialFormData);
  ReactModalInitialProps.resetAll = () => {
    setModalConfig(ReactModalInitialProps);
  };
  const [modalConfig, setModalConfig] = useState(ReactModalInitialProps);
  const columns = [
    {
      Header: 'User',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'ID',
          Cell: ({ row }: any) => {
            return row.original.id;
          },
        },
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Active',
          accessor: 'active',
          Cell: ({ row }: any) => {
            const active = row.original.active;
            if (active === true) {
              return 'Active';
            } else {
              return 'Inactive';
            }
          },
        },
        {
          Header: 'Action',
          accessor: 'id',
          Cell: ({ row }: any) => {
            return (
              <div className='d-flex'>
                <EditIcon
                  onClick={() => {
                    openModalEdit(row.original);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Edit />
                </EditIcon>
                <DeleteIcon
                  onClick={() => {
                    removeAgent(row.original.username);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Delete />
                </DeleteIcon>
              </div>
            );
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchUserHooks = async () => {
      const result: any = await getAllUser(auth.data.token);
      if (result.status === true) {
        setData(result.body);
      } else {
        setError(result.body.message);
      }
    };
    fetchUserHooks();
  }, [auth]);

  const fetchUser = async () => {
    const result: any = await getAllUser(auth.data.token);
    if (result.status === true) {
      setData(result.body);
    } else {
      setError(result.body.message);
    }
  };

  const reFetchOrRaiseError = (result: any) => {
    if (result.status === true) {
      fetchUser();
    } else {
      setError(result.body.message);
    }
  };

  const openModalAdd = () => {
    overrideData(InitialFormData);
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Add User',
      actionLabel: 'Add',
      actionColor: '#77dd77',
    });
  };

  const openModalEdit = (data: any) => {
    overrideData({
      username: data.username,
      password: data.password,
      agentId: data.agent_id,
    });
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Change Password',
      actionLabel: 'Edit',
      actionColor: '#ff964f',
    });
  };

  const removeAgent = async (username: string) => {
    const result: any = await deleteUser(auth.data.token, username);
    reFetchOrRaiseError(result);
  };

  const editAgent = async () => {
    const result: any = await changePassword(
      auth.data.token,
      formData.password,
      formData.username
    );
    reFetchOrRaiseError(result);
  };

  const addAgent = async () => {
    const result: any = await saveUser(auth.data.token, formData);
    reFetchOrRaiseError(result);
  };

  return (
    <Container title={'User'}>
      {error !== '' ? (
        <Alert className='mt-3' variant='warning'>
          {error}
        </Alert>
      ) : (
        <>
          <div className='d-flex justify-content-end'>
            <button onClick={openModalAdd} className='btn btn-info'>
              Add User
            </button>
          </div>
          <Table<UserData> name={'userTable'} columns={columns} data={data} />
        </>
      )}

      {/* Modal */}
      <ReactModal
        {...modalConfig}
        onClickAdd={addAgent}
        onClickEdit={editAgent}
      >
        <p>Username</p>
        <input
          type='text'
          name='username'
          disabled={modalConfig.actionLabel === 'Edit' ? true : false}
          value={formData.username}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Password</p>
        <input
          type='password'
          name='password'
          value={formData.password}
          onChange={setFormData}
          className='form-control'
        />
        {modalConfig.actionLabel === 'Edit' ? (
          ''
        ) : (
          <>
            <p className='mt-2'>Agent ID</p>
            <input
              type='text'
              name='agentId'
              value={formData.agentId}
              onChange={setFormData}
              className='form-control'
            />{' '}
          </>
        )}
        <hr />
      </ReactModal>
    </Container>
  );
};

export default User;
