import { CONFIG_BACKEND_URL } from '../utils/conf';

export interface QuestionInterface {
  id: number;
  question: string;
  answer: string;
  templateId: number;
  callId: string;
  status: string | boolean;
}

export const generateQuestions = async (
  token: string,
  count: number,
  callId: string
) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({ count: count });
  const requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(
    `${CONFIG_BACKEND_URL}/calls/${callId}/generate_questions`,
    requestOptions
  )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const patchQuestion = async (
  token: string,
  id: number,
  status: boolean
) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({ legit: status });
  const requestOptions: any = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/questions/${id}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const getAllQuestions = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/questions/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const deleteQuestion = async (token: string, id: number) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/questions/${id}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return { message: 'success' };
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};
