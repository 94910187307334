import { TableSortLabel, TextField } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import cx from 'classnames';
import React, {
  CSSProperties,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  useEffect,
} from 'react';
import {
  Cell,
  //   CellProps,
  FilterProps,
  HeaderGroup,
  HeaderProps,
  //   Hooks,
  Meta,
  Row,
  TableInstance,
  TableOptions,
  useColumnOrder,
  useExpanded,
  useFilters,
  useFlexLayout,
  //   useGroupBy,
  usePagination,
  useResizeColumns,
  //   useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';

import { camelToWords, useDebounce, useLocalStorage } from '../../../utils';
import { FilterChipBar } from './FilterChipBar';
import { fuzzyTextFilter, numericTextFilter } from './filters';
import { ResizeHandle } from './ResizeHandle';
import { TablePagination } from './TablePagination';
import {
  // HeaderCheckbox,
  // RowCheckbox,
  useStyles,
} from './TableStyles';
import { TableToolbar } from './TableToolbar';
import { TooltipCell } from './TooltipCell';

export interface TableInterface<
  T extends Record<string, unknown> = Record<string, any>
> extends TableOptions<T> {
  name: string;
  onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
  onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
  onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
  onClick?: (row: Row<T>) => void;
}

const DefaultHeader: React.FC<HeaderProps<any>> = ({ column }) => (
  <>{column.id.startsWith('_') ? null : camelToWords(column.id)}</>
);

function DefaultColumnFilter<T extends Record<string, any>>({
  column: { id, filterValue, setFilter, render },
}: FilterProps<T>) {
  const [value, setValue] = React.useState(filterValue || '');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || '');
  }, [filterValue]);

  return (
    <TextField
      name={id}
      label={render('Header')}
      value={value}
      variant={'standard'}
      onChange={handleChange}
      onBlur={(e) => {
        setFilter(e.target.value || undefined);
      }}
    />
  );
}

// eslint-disable-next-line
const getStyles = <T extends Record<string, any>>(
  props: any,
  // eslint-disable-next-line
  disableResizing = false,
  align = 'left'
) => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];

// const selectionHook = (hooks: Hooks<any>) => {
//   hooks.allColumns.push((columns) => [
//     // Let's make a column for selection
//     {
//       id: '_selector',
//       disableResizing: true,
//       disableGroupBy: true,
//       minWidth: 45,
//       width: 45,
//       maxWidth: 45,
//       // The header can use the table's getToggleAllRowsSelectedProps method
//       // to render a checkbox
//       Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
//         <HeaderCheckbox {...getToggleAllRowsSelectedProps()} />
//       ),
//       // The cell can use the individual row's getToggleRowSelectedProps method
//       // to the render a checkbox
//       Cell: ({ row }: CellProps<any>) => (
//         <RowCheckbox {...row.getToggleRowSelectedProps()} />
//       ),
//     },
//     ...columns,
//   ]);
//   hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
//     // fix the parent group of the selection button to not be resizable
//     const selectionGroupHeader = headerGroups[0].headers[0];
//     selectionGroupHeader.canResize = false;
//   });
// };

const headerProps = <T extends Record<string, any>>(
  props: any,
  { column }: Meta<T, { column: HeaderGroup<T> }>
) => getStyles(props, column && column.disableResizing, column && column.align);

const cellProps = <T extends Record<string, any>>(
  props: any,
  { cell }: Meta<T, { cell: Cell<T> }>
) =>
  getStyles(
    props,
    cell.column && cell.column.disableResizing,
    cell.column && cell.column.align
  );

const defaultColumn = {
  Filter: DefaultColumnFilter,
  Cell: TooltipCell,
  Header: DefaultHeader,
  // When using the useFlexLayout:
  minWidth: 30, // minWidth is only used as a limit for resizing
  width: 150, // width is used for both the flex-basis and flex-grow
  maxWidth: 200, // maxWidth is only used as a limit for resizing
};

const hooks = [
  useColumnOrder,
  useFilters,
  //   useGroupBy,
  useSortBy,
  useExpanded,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  //   useRowSelect,
];

const filterTypes = {
  fuzzyText: fuzzyTextFilter,
  numeric: numericTextFilter,
};

export function Table<T extends Record<string, any>>(
  props: PropsWithChildren<TableInterface<T>>
): ReactElement {
  const { name, columns, onAdd, onDelete, onEdit, onClick } = props;
  const classes = useStyles();

  const [initialState, setInitialState] = useLocalStorage(
    `tableState:${name}`,
    {}
  );
  const instance = useTable<T>(
    {
      ...props,
      columns,
      filterTypes,
      defaultColumn,
      initialState,
    },
    ...hooks
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    page,
    prepareRow,
    state,
  } = instance;
  const debouncedState = useDebounce(state, 500);

  useEffect(() => {
    const {
      sortBy,
      filters,
      pageSize,
      columnResizing,
      hiddenColumns,
    } = debouncedState;
    const val = {
      sortBy,
      filters,
      pageSize,
      columnResizing,
      hiddenColumns,
    };
    setInitialState(val);
  }, [setInitialState, debouncedState]);

  const cellClickHandler = (cell: Cell<T>) => () => {
    onClick && cell.column.id !== '_selector' && onClick(cell.row);
  };

  return (
    <>
      <TableToolbar instance={instance} {...{ onAdd, onDelete, onEdit }} />
      <FilterChipBar<T> instance={instance} />
      <div className={classes.tableTable} {...getTableProps()}>
        <div>
          {headerGroups.map((headerGroup, iHeaderGroup) => (
            <div
              {...headerGroup.getHeaderGroupProps()}
              className={classes.tableHeadRow}
              key={iHeaderGroup}
            >
              {headerGroup.headers.map((column, iColumn) => {
                const style = {
                  textAlign: column.align ? column.align : 'left ',
                } as CSSProperties;
                return (
                  <div
                    {...column.getHeaderProps(headerProps)}
                    className={classes.tableHeadCell}
                    key={iColumn}
                  >
                    {column.canGroupBy && (
                      <TableSortLabel
                        active
                        direction={column.isGrouped ? 'desc' : 'asc'}
                        IconComponent={KeyboardArrowRight}
                        {...column.getGroupByToggleProps()}
                        className={classes.headerIcon}
                      />
                    )}
                    {column.canSort ? (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                        {...column.getSortByToggleProps()}
                        className={classes.tableSortLabel}
                        style={style}
                      >
                        {column.render('Header')}
                      </TableSortLabel>
                    ) : (
                      <div style={style} className={classes.tableLabel}>
                        {column.render('Header')}
                      </div>
                    )}
                    {column.canResize && <ResizeHandle column={column} />}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className={classes.tableBody}>
          {page.map((row, iRow) => {
            prepareRow(row);
            return (
              <div
                {...row.getRowProps()}
                className={cx(classes.tableRow, {
                  rowSelected: row.isSelected,
                })}
                key={iRow}
              >
                {row.cells.map((cell: any, iCell) => {
                  return (
                    <div
                      {...cell.getCellProps(cellProps)}
                      onClick={cellClickHandler(cell)}
                      className={classes.tableCell}
                      key={iCell}
                    >
                      {cell.isGrouped ? (
                        <>
                          <TableSortLabel
                            classes={{
                              iconDirectionAsc: classes.iconDirectionAsc,
                              iconDirectionDesc: classes.iconDirectionDesc,
                            }}
                            active
                            direction={row.isExpanded ? 'desc' : 'asc'}
                            IconComponent={KeyboardArrowUp}
                            {...row.getToggleRowExpandedProps()}
                            className={classes.cellIcon}
                          />{' '}
                          {cell.render('Cell')} ({row.subRows.length})
                        </>
                      ) : cell.isAggregated ? (
                        cell.render('Aggregated')
                      ) : cell.isPlaceholder ? null : (
                        cell.render('Cell')
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <TablePagination<T> instance={instance} />
    </>
  );
}
