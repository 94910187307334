import styled from 'styled-components';

export const Identity = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
`;

export const TitleCustomerInformation = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #565656;
`;

export const ButtonEndCall = styled.div`
  background-color: #e24c4b;
  padding: 6px 18px;
  color: white;
  border: none;
  border-radius: 4px;

  ${this}:hover {
    cursor: pointer;
    background-color: #8c0100;
  }

  ${this}:disabled {
    cursor: default;
    text-decoration: none;
    color: #cecece;
  }
`;
