import styled from 'styled-components';

export const SidebarContainer = styled.div`
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background-color: #242424;
  overflow-x: hidden;
  padding-top: 20px;
`;

export const SidebarUser = styled.div`
  display: block;
  color: white;
  padding: 0.5rem 1rem;
  height: 4rem;
`;

export const SidebarIconUser = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: white;
  text-align: center;
  padding-top: 0.7rem;
  color: #242424;
  font-weight: 500;
`;

export const SidebarContent = styled.div`
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  color: white;
  display: block;
  text-decoration: none;

  ${this}:hover {
    background-color: white;
    cursor: pointer;
    color: #242424;
    text-decoration: none;
  }
`;

export const SidebarContentIcon = styled.div`
  width: 2rem;
`;

export const SidebarLine = styled.div`
  border-color: #c7d4d8;
`;
