import React, { useContext } from 'react';
import { AuthContext } from '../../../utils/Authentication';
import Container from '../../Components/Container/Container';

const HomePage = () => {
  const auth = useContext(AuthContext);
  return (
    <Container title={'Home'}>
      Hello {auth.auth.data.username}, Welcome to CRM Dashboard!
    </Container>
  );
};

export default HomePage;
