import { TemplateForm } from './../Admin/Pages/Templates/Templates';
import { CONFIG_BACKEND_URL } from '../utils/conf';

export interface TemplateInterface {
  id: number;
  question: string;
  answer: string;
}

export const getAllTemplates = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    headers: myHeaders,
    method: 'GET',
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/templates/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const deleteTemplate = async (token: string, id: number) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/templates/${id}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return { message: 'success' };
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const saveTemplate = async (token: string, template: TemplateForm) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);
  const raw = JSON.stringify({
    question: template.question,
    answer: template.answer,
  });
  const requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/templates/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const updateTemplate = async (token: string, template: TemplateForm) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);
  const raw = JSON.stringify({
    question: template.question,
    answer: template.answer,
  });
  const requestOptions: any = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/templates/${template.id}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};
