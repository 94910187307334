import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CRMLeftContainer } from './ContainerCRMStyle';
import TopBar from '../TopBar/TopBar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface ContainerCRMProps {
  children: React.ReactNode;
  leftContent: React.ReactNode;
  activeTopBar: number;
}

const ContainerCRM = ({
  children,
  leftContent,
  activeTopBar,
}: ContainerCRMProps) => {
  return (
    <Row>
      <Col md={3}>
        <FiberManualRecordIcon
          style={{
            color: ' rgba(255, 226, 146, 0.35)',
            position: 'absolute',
            marginLeft: '-210px',
            marginTop: '-210px',
            fontSize: '420px',
          }}
          className='mr-2'
        />
        <CRMLeftContainer style={{ marginTop: '100px' }}>
          {leftContent}
        </CRMLeftContainer>
      </Col>
      <Col md={9}>
        <TopBar activeTopBar={activeTopBar} /> {children}
      </Col>
    </Row>
  );
};

export default ContainerCRM;
