import React, { useEffect, useContext, useState } from 'react';
import { AuthContext } from '../../../utils/Authentication';
import Container from '../../Components/Container/Container';
import { Table } from '../../Components/Table/Table';
import {
  CustomerInterface,
  getAllCustomer,
  deleteCustomer,
  saveCustomer,
  updateCustomer,
} from '../../../Model/customer';
import { Alert } from 'react-bootstrap';
import { Edit, Delete } from '@material-ui/icons';
import { EditIcon, DeleteIcon } from './CustomerStyle';
import { ReactModal, ReactModalProps } from '../../Components/Modal/ReactModal';
import { useForm } from '../../../utils/useForm';

interface CustomerData extends CustomerInterface {
  subRows?: CustomerData[];
}

const ReactModalInitialProps: ReactModalProps = {
  isOpen: false,
  children: <></>,
  title: '',
  actionLabel: '',
  actionColor: '',
  resetAll: () => undefined,
};

const InitialFormData: CustomerInterface = {
  customerId: '',
  gender: 'female',
  enrollments: 0,
  fullName: '',
  address: '',
  dateOfBirth: '',
  email: '',
};

const Customer = () => {
  const [formData, setFormData, overrideData] = useForm(InitialFormData);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  ReactModalInitialProps.resetAll = () => {
    setModalConfig(ReactModalInitialProps);
  };
  const [modalConfig, setModalConfig] = useState(ReactModalInitialProps);
  const authentication = useContext(AuthContext);
  const { auth } = authentication;
  const columns = [
    {
      Header: 'Customer',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'Customer ID',
          accessor: 'customer_id',
        },
        {
          Header: 'Gender',
          accessor: 'gender',
        },
        {
          Header: 'Enrollments',
          accessor: 'enrollments',
        },
        {
          Header: 'Full Name',
          accessor: 'full_name',
        },
        {
          Header: 'Address',
          accessor: 'address',
        },
        {
          Header: 'Date of Birth',
          accessor: 'date_of_birth',
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Action',
          accessor: 'id',
          Cell: ({ row }: any) => {
            return (
              <div className='d-flex'>
                <EditIcon
                  onClick={() => {
                    openModalEdit(row.original);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Edit />
                </EditIcon>
                <DeleteIcon
                  onClick={() => {
                    removeCustomer(row.original.customer_id);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Delete />
                </DeleteIcon>
              </div>
            );
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchCustomerEffect = async () => {
      const customerData: any = await getAllCustomer(auth.data.token);
      if (customerData.status === true) {
        setData(customerData.body);
      } else {
        setError(customerData.body.message);
      }
    };
    fetchCustomerEffect();
  }, [auth]);

  const fetchCustomer = async () => {
    const customerData: any = await getAllCustomer(auth.data.token);
    if (customerData.status === true) {
      setData(customerData.body);
    } else {
      setError(customerData.body.message);
    }
  };

  const openModalAdd = () => {
    overrideData(InitialFormData);
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Add Customer',
      actionLabel: 'Add',
      actionColor: '#77dd77',
    });
  };

  const openModalEdit = (data: any) => {
    overrideData({
      customerId: data.customer_id,
      gender: data.gender,
      enrollments: data.enrollments,
      fullName: data.full_name,
      address: data.address,
      dateOfBirth: data.date_of_birth,
      email: data.email,
    });
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Edit Customer',
      actionLabel: 'Edit',
      actionColor: '#ff964f',
    });
  };

  const reFetchOrRaiseError = (result: any) => {
    if (result.status === true) {
      fetchCustomer();
    } else {
      setError(result.body.message);
    }
  };

  const removeCustomer = async (id: string) => {
    const result: any = await deleteCustomer(auth.data.token, id);
    reFetchOrRaiseError(result);
  };

  const editCustomer = async () => {
    const result: any = await updateCustomer(auth.data.token, formData);
    reFetchOrRaiseError(result);
  };

  const addCustomer = async () => {
    const result: any = await saveCustomer(auth.data.token, formData);
    reFetchOrRaiseError(result);
  };

  return (
    <Container title={'Customer'}>
      {error !== '' ? (
        <Alert className='mt-3' variant='warning'>
          {error}
        </Alert>
      ) : (
        <>
          <div className='d-flex justify-content-end'>
            <button onClick={openModalAdd} className='btn btn-info'>
              Add Customer
            </button>
          </div>
          <Table<CustomerData>
            name={'customerTable'}
            columns={columns}
            data={data}
          />
        </>
      )}
      {/* Modal */}
      <ReactModal
        {...modalConfig}
        onClickAdd={addCustomer}
        onClickEdit={editCustomer}
      >
        <p>Customer ID</p>
        <input
          type='text'
          name='customerId'
          disabled={modalConfig.actionLabel === 'Edit' ? true : false}
          value={formData.customerId}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Gender</p>
        <select
          name='gender'
          id='gender'
          className='form-control'
          value={formData.gender}
          onChange={setFormData}
        >
          <option value='female'>Female</option>
          <option value='male'>Male</option>
        </select>
        <p className='mt-2'>Full Name</p>
        <input
          type='text'
          name='fullName'
          value={formData.fullName}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Address</p>
        <input
          type='text'
          name='address'
          value={formData.address}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Date of Birth</p>
        <input
          type='date'
          name='dateOfBirth'
          value={formData.dateOfBirth}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Email</p>
        <input
          type='email'
          name='email'
          value={formData.email}
          onChange={setFormData}
          className='form-control'
        />
        <hr />
      </ReactModal>
    </Container>
  );
};

export default Customer;
