// Component to redirect user if not yet log in
// This component inherits everything from Route from react-router-dom

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from './Authentication';

export const ProtectedRoute = ({
  component: Component,
  onlyAdmin,
  ...rest
}: any) => {
  const authentication = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (authentication.auth.isAuth === true) {
          if (authentication.auth.data.username === 'admin') {
            if (onlyAdmin) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/admin/home',
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          } else {
            if (!onlyAdmin) {
              return <Component {...props} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: '/crm',
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
