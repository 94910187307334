import styled from 'styled-components';

export const FixedContainer = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  position: fixed;
`;

export const CustomButton = styled.button`
  background-color: #f25822;
  color: white;
  font-weight: 500;

  ${this}:hover {
    background-color: #e33e05;
  }
`;

export const LeftContainer = styled.div`
  background-color: #f3f8ff;
  height: 100vh;
`;

export const TitleBrand = styled.div`
  color: white;
  font-weight: bold;
  font-size: 60px;
  text-shadow: 6px 0px 10px rgba(59, 38, 186, 0.48);
`;

export const Welcome = styled.div`
  font-weight: 500;
  font-size: 70px;
  color: rgba(34, 0, 106, 0.6);
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
`;

export const Disclaimer = styled.div`
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 6px 0px 10px rgba(59, 38, 186, 0.48);
`;

export const SignIn = styled.div`
  font-weight: 500;
  font-size: 30px;
  color: #6b6767;
`;

export const CustomImage = styled.img`
  width: 70%;
`;

export const CallCenter = styled.span`
  ${this}:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ClickableDiv = styled.div`
  ${this}:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
