import { clearCredential } from '../utils/session';
import { CONFIG_BACKEND_URL } from '../utils/conf';

export interface Authentication {
  username: string;
  password: string;
}

const configPost = (raw: Authentication): any => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  return {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: 'follow',
  };
};

export const AuthUser = async (user: Authentication) => {
  const config = configPost(user);
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/user/login`, config)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const LogoutUser = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
  };

  await fetch(`${CONFIG_BACKEND_URL}/user/logout/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then(() => {
      clearCredential();
    })
    .catch((error) => console.log('error', error));
};
