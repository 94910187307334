import React from 'react';
import { useTable, usePagination } from 'react-table';
import { BackButton } from './CustomTableStyle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

function Table({ columns, data }: any) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 },
    },
    usePagination
  );
  return (
    <>
      <table {...getTableProps()} className='table'>
        <thead className='text-dark' style={{ backgroundColor: '#C5E6FF' }}>
          {headerGroups.map((headerGroup, iHeaderGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={iHeaderGroup}>
              {headerGroup.headers.map((column, iColumn) => (
                <th {...column.getHeaderProps()} key={iColumn}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={i}>
                {row.cells.map((cell, iCell) => {
                  return (
                    <td {...cell.getCellProps()} key={iCell}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='d-flex justify-content-center align-items-center'>
        <BackButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <ChevronLeftIcon />
        </BackButton>{' '}
        <BackButton onClick={() => nextPage()} disabled={!canNextPage}>
          <ChevronRightIcon />
        </BackButton>{' '}
        <div style={{ width: '100px' }}></div>
      </div>
    </>
  );
}

function CustomTable({ data, column }: any) {
  const processedData = React.useMemo(() => data, [data]);

  return <Table columns={column} data={processedData} />;
}

export default CustomTable;
