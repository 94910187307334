import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  FirstGreeting,
  SecondGreeting,
  Waiting,
  LandingCustomImage,
} from '../Landing/LandingStyle';
import EnrollmentImage from '../../../Assets/pert.png';
import EnrollConfirm from '../../../Assets/Group 14.svg';
import Legit from '../../../Assets/Legit.svg';
import Fraud from '../../../Assets/Fraud.svg';
import ContainerCRM from '../../Components/ContainerCRM/ContainerCRM';
import { Alert } from 'react-bootstrap';
import {
  EnrollButton,
  ConfirmEnrollment,
  TitleConfirmEnrollment,
} from './EnrollmentStyle';

import { AgentInterface } from '../../../Model/agent';
import { CustomerDataCRM } from '../Entry/Entry';
import ModalCRM from '../../Components/ModalCRM/ModalCRM';
import { ReactSVG } from 'react-svg';
import { CRMStateInterface } from '../Entry/Entry';
import InformationCard from '../../Components/InformationCard/InformationCard';
import { generateQuestions } from '../../../Model/question';
import { AuthContext } from '../../../utils/Authentication';
import { QuestionInterface } from '../../../Model/question';
import { getQuestions, saveQuestions } from '../../../utils/session';
import { enrollCallPost, setCallStatus } from '../../../Model/call';
import ClipLoader from 'react-spinners/ClipLoader';

import CustomerCard from '../../Components/CustomerCard/CustomerCard';
import TableQuestion from '../../Components/TableQuestion/TableQuestion';

interface LeftContentProps {
  name: string;
}

const LeftContent = ({ name }: LeftContentProps) => {
  return (
    <>
      <div>
        <FirstGreeting>Halo, </FirstGreeting>
        <SecondGreeting>{name}</SecondGreeting>
      </div>
      <Waiting className='mt-2'>
        Have a nice day, <br /> give your best performance
      </Waiting>
      <div className='d-flex justify-content-center'>
        <LandingCustomImage
          src={EnrollmentImage}
          alt='pop-img'
          className='img-fluid'
        />
      </div>
    </>
  );
};

interface EnrollmentProps {
  crmState: CRMStateInterface;
  customerData: CustomerDataCRM;
  agent: AgentInterface;
  changeGender: (gender: string) => void;
  errorMsg: string;
  raiseErrorMessage: (msg: string) => void;
  endCall: () => void;
  setCustomerData: Dispatch<SetStateAction<CustomerDataCRM>>;
}

const InitialQuestionState: QuestionInterface[] = [
  { id: 0, question: '', answer: '', templateId: 0, callId: '', status: '' },
];

interface ModalFraudState {
  status: boolean;
  legit: 'not_legit' | 'legit';
}

const InitialModalFraudState: ModalFraudState = {
  status: false,
  legit: 'not_legit',
};

interface EnrollingStateInterface {
  isEnrolling: boolean;
  isEnrollingSuccess: boolean;
  isEnrollingFailed: boolean;
}

const InitialEnrollingState: EnrollingStateInterface = {
  isEnrolling: false,
  isEnrollingSuccess: false,
  isEnrollingFailed: false,
};

type IGender = 'male' | 'female';
const InitialGender: IGender = 'female';

const Enrollment = ({
  crmState,
  customerData,
  agent,
  changeGender,
  errorMsg,
  raiseErrorMessage,
  endCall,
  setCustomerData,
}: EnrollmentProps) => {
  const [gender, setGender] = useState<IGender>(InitialGender);
  const [modalStatus, setModalStatus] = useState(false);
  const [modalStatusFraud, setModalStatusFraud] = useState(
    InitialModalFraudState
  );
  const [questions, setQuestions] = useState(InitialQuestionState);
  const authentication = useContext(AuthContext);
  const x = useRef(false);
  const [enrolling, setEnrolling] = useState(InitialEnrollingState);
  const [isAlreadySubmit, setIsAlreadySubmit] = useState(false);

  useEffect(() => {
    const fetchAllQuestion = async () => {
      const result: any = await generateQuestions(
        authentication.auth.data.token,
        10,
        crmState.callId
      );
      if (result.status === true) {
        const body: QuestionInterface[] = result.body;
        saveQuestions(body);
        setQuestions(body);
      } else {
        // raiseErrorMessage(result.body.message);
      }
    };
    const questionFromStorage = getQuestions();
    if (questionFromStorage === null) {
      if (x.current === false) {
        fetchAllQuestion();
      }
    } else {
      setQuestions(questionFromStorage);
    }
    x.current = true;
  }, [authentication, crmState, raiseErrorMessage]);

  const updateGender = (gender: 'male' | 'female') => {
    changeGender(gender);
    setGender(gender);
  };

  useEffect(() => {
    setGender(customerData.gender);
  }, [customerData]);

  return (
    <ContainerCRM
      leftContent={<LeftContent name={agent.name} />}
      activeTopBar={3}
    >
      <div className='p-4'>
        {errorMsg !== '' ? (
          <Alert variant={'danger'} className='border-0 shadow-sm'>
            {errorMsg}
          </Alert>
        ) : (
          ''
        )}
        <CustomerCard
          customerData={customerData}
          gender={gender}
          endCall={endCall}
          updateGender={updateGender}
        />
      </div>

      {/* Question */}

      <div className='px-4'>
        <TableQuestion
          questions={questions}
          raiseErrorMessage={raiseErrorMessage}
          setQuestions={setQuestions}
          setModalStatusFraud={setModalStatusFraud}
          isAlreadySubmit={isAlreadySubmit}
        >
          <InformationCard
            verified={crmState.verified}
            score={crmState.score}
            duration={crmState.duration}
            minVoicedDuration={customerData.minVoicedDuration}
            isVerifying={customerData.isVerifying}
            setModalStatus={setModalStatus}
            isAlreadySubmit={isAlreadySubmit}
            crmState={crmState}
          />
        </TableQuestion>
      </div>

      {/* Modal */}
      <ModalCRM isOpen={modalStatus}>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <ReactSVG src={EnrollConfirm} />
          <ConfirmEnrollment>
            Do you want to enroll this recording?
          </ConfirmEnrollment>
          {enrolling.isEnrollingFailed === true ? (
            <Alert variant={'danger'} className='d-flex mt-2'>
              Enrollment Failed
            </Alert>
          ) : (
            ''
          )}

          {enrolling.isEnrollingSuccess === true ? (
            <Alert variant={'success'} className='d-flex mt-2'>
              Enrollment Success
            </Alert>
          ) : (
            ''
          )}
          <div className='d-flex mt-1'>
            <button
              onClick={() => {
                setModalStatus(false);
              }}
              className='btn btn-light mr-4'
            >
              Cancel
            </button>
            <EnrollButton
              onClick={async () => {
                let isFailed = false;
                setEnrolling((c) => ({ ...c, isEnrolling: true }));
                const result: any = await enrollCallPost(
                  authentication.auth.data.token,
                  crmState.callId
                );
                await setCallStatus(
                  authentication.auth.data.token,
                  modalStatusFraud.legit,
                  crmState.callId
                );
                if (result.status === true) {
                  setEnrolling((c) => ({
                    ...c,
                    isEnrollingSuccess: true,
                    isEnrolling: false,
                  }));
                  setIsAlreadySubmit(true);
                  setCustomerData((c) => ({
                    ...c,
                    enrollments: c.enrollments + 1,
                  }));
                  setTimeout(() => {
                    setEnrolling((c) => ({
                      ...c,
                      isEnrollingSuccess: false,
                    }));
                  }, 3000);
                } else {
                  isFailed = true;
                  setEnrolling((c) => ({
                    ...c,
                    isEnrollingFailed: true,
                    isEnrolling: false,
                  }));
                  setTimeout(() => {
                    setEnrolling((c) => ({
                      ...c,
                      isEnrollingFailed: false,
                    }));
                  }, 3000);
                }
                setTimeout(() => {
                  if (!isFailed) {
                    setModalStatus(false);
                  }
                }, 2000);
              }}
              disabled={enrolling.isEnrolling}
            >
              {enrolling.isEnrollingFailed ? 'Retry' : 'Enroll'}
              <ClipLoader
                loading={enrolling.isEnrolling}
                size={24}
                color={'#f25822'}
              />
            </EnrollButton>
          </div>
        </div>
      </ModalCRM>

      {/* Modal Legit Fraud */}
      <ModalCRM isOpen={modalStatusFraud.status}>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          {modalStatusFraud.legit === 'legit' ? (
            <ReactSVG src={Legit} />
          ) : (
            <ReactSVG src={Fraud} />
          )}

          <TitleConfirmEnrollment>Are you sure?</TitleConfirmEnrollment>
          <ConfirmEnrollment>
            This is {modalStatusFraud.legit === 'legit' ? 'Legit' : 'Fraud'}{' '}
            data customer
          </ConfirmEnrollment>
          <div className='d-flex mt-4'>
            <button
              onClick={() => {
                setModalStatusFraud((c) => ({
                  ...c,
                  status: false,
                }));
              }}
              className='btn btn-light mr-5'
            >
              Cancel
            </button>
            <EnrollButton
              onClick={async () => {
                setModalStatusFraud((c) => ({
                  ...c,
                  status: false,
                }));
                setCallStatus(
                  authentication.auth.data.token,
                  modalStatusFraud.legit,
                  crmState.callId
                );
              }}
            >
              Yes
            </EnrollButton>
          </div>
        </div>
      </ModalCRM>
    </ContainerCRM>
  );
};

export default Enrollment;
