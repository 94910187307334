import React, { useEffect, useContext, useState } from 'react';
import {
  AgentInterface,
  getAllAgents,
  updateAgentId,
  updateExtension,
} from '../../../Model/agent';
import { AuthContext } from '../../../utils/Authentication';
import Container from '../../Components/Container/Container';
import { EditIcon } from '../Customer/CustomerStyle';
import { Edit } from '@material-ui/icons';
import { Alert } from 'react-bootstrap';
import { Table } from '../../Components/Table/Table';
import { ReactModal, ReactModalProps } from '../../Components/Modal/ReactModal';
import { useForm } from '../../../utils/useForm';

interface AgentData extends AgentInterface {
  subRows?: AgentData[];
}

const ReactModalInitialProps: ReactModalProps = {
  isOpen: false,
  children: <></>,
  title: '',
  actionLabel: '',
  actionColor: '',
  resetAll: () => undefined,
};

const InitialFormData: AgentInterface = {
  username: '',
  active: false,
  name: '',
  agentId: '',
  extension: '',
};

const Agent = () => {
  const authentication = useContext(AuthContext);
  const { auth } = authentication;
  ReactModalInitialProps.resetAll = () => {
    setModalConfig(ReactModalInitialProps);
  };
  const [modalConfig, setModalConfig] = useState(ReactModalInitialProps);
  const [formData, setFormData, overrideData] = useForm(InitialFormData);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');
  const columns = [
    {
      Header: 'Agent',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'Agent ID',
          accessor: 'agent_id',
        },
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Extension',
          accessor: 'extension',
        },
        {
          Header: 'Active',
          accessor: 'active',
          Cell: ({ row }: any) => {
            const active = row.original.active;
            if (active === true) {
              return 'Active';
            } else {
              return 'Inactive';
            }
          },
        },
        {
          Header: 'Action',
          accessor: 'id',
          Cell: ({ row }: any) => {
            return (
              <div className='d-flex'>
                <EditIcon
                  onClick={() => {
                    openModalEdit(row.original);
                  }}
                  className='shadow-sm rounded-circle p-1 border border-light'
                >
                  <Edit />
                </EditIcon>
              </div>
            );
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchAgentHooks = async () => {
      const result: any = await getAllAgents(auth.data.token);
      if (result.status === true) {
        setData(result.body);
      } else {
        setError(result.body.message);
      }
    };
    fetchAgentHooks();
  }, [auth]);

  const fetchAgent = async () => {
    const result: any = await getAllAgents(auth.data.token);
    if (result.status === true) {
      setData(result.body);
    } else {
      setError(result.body.message);
    }
  };

  const openModalEdit = (data: any) => {
    overrideData({
      username: data.username,
      active: data.active,
      name: data.name,
      agentId: data.agent_id,
      extension: data.extension,
    });
    setModalConfig({
      ...modalConfig,
      isOpen: true,
      title: 'Edit Agent',
      actionLabel: 'Edit',
      actionColor: '#ff964f',
    });
  };

  const reFetchOrRaiseError = (result: any) => {
    if (result.status === true) {
      fetchAgent();
    } else {
      setError(result.body.message);
    }
  };

  const editAgent = async () => {
    const result: any = await updateAgentId(auth.data.token, formData);
    const result2: any = await updateExtension(auth.data.token, formData);
    reFetchOrRaiseError(result);
    reFetchOrRaiseError(result2);
  };

  return (
    <Container title={'Agent'}>
      {error !== '' ? (
        <Alert className='mt-3' variant='warning'>
          {error}
        </Alert>
      ) : (
        <>
          <Table<AgentData> name={'agentTable'} columns={columns} data={data} />
        </>
      )}

      {/* Modal */}
      <ReactModal
        {...modalConfig}
        onClickAdd={() => undefined}
        onClickEdit={editAgent}
      >
        <p>Username</p>
        <input
          type='text'
          name='username'
          value={formData.username}
          disabled={true}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Agent ID</p>
        <input
          type='text'
          name='agentId'
          value={formData.agentId}
          onChange={setFormData}
          className='form-control'
        />
        <p className='mt-2'>Extension</p>
        <input
          type='text'
          name='extension'
          value={formData.extension}
          onChange={setFormData}
          className='form-control'
        />
        <hr />
      </ReactModal>
    </Container>
  );
};

export default Agent;
