export const convertHMS = (sec: number): string => {
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec - hours * 3600) / 60);
  const seconds = sec - hours * 3600 - minutes * 60;
  let hoursString: string = hours.toString();
  let minutesString: string = minutes.toString();
  let secondsString: string = seconds.toString();
  if (hours !== 0) {
    hoursString = hours + ' h';
  } else {
    hoursString = '';
  }
  if (minutes !== 0) {
    minutesString = minutes + ' m';
  } else {
    minutesString = '';
  }
  if (seconds !== 0) {
    secondsString = seconds + ' s';
  } else {
    secondsString = '';
  }
  if (sec === 0) return '0 s';
  return `${hoursString} ${minutesString} ${secondsString} `;
};
