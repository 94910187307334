import { UserForm } from '../Admin/Pages/User/User';
import { CONFIG_BACKEND_URL } from '../utils/conf';

export interface UserInterface {
  agentId: number;
  username: string;
  active: boolean;
}

export const getAllUser = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/user/manage/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const saveUser = async (token: string, user: UserForm) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({
    username: user.username,
    password: user.password,
    agent_id: user.agentId,
  });

  const requestOptions: any = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/user/register/`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const changePassword = async (
  token: string,
  password: string,
  username: string
) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  myHeaders.append('Content-Type', 'application/json');
  const raw = JSON.stringify({ password: password });
  const requestOptions: any = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/user/manage/${username}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};

export const deleteUser = async (token: string, username: string) => {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);
  const requestOptions: any = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };
  const data = { status: false, body: {} };
  await fetch(`${CONFIG_BACKEND_URL}/user/manage/${username}`, requestOptions)
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.text();
    })
    .then((result) => {
      data.status = true;
      data.body = result;
    })
    .catch((error) => {
      data.status = false;
      data.body = error;
    });
  return data;
};
