import styled from 'styled-components';

export const EnrollmentButton = styled.button`
  background-color: #00a654;
  padding: 6px 18px;
  color: white;
  border: none;
  border-radius: 4px;

  ${this}:hover {
    cursor: pointer;
    background-color: #036b38;
  }

  ${this}:disabled {
    cursor: default;
    text-decoration: none;
    color: white;
    background-color: #cecece;
  }
`;

export const InformationCardContainer = styled.div<{ isWarning?: boolean }>`
  background-color: ${(props) => (props.isWarning ? '#FFF7CD' : 'white')};
  border-radius: 5px;
  font-weight: 500;
  padding: 1rem;
  color: black;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin: 2px;
  height: 12.5rem;
`;
