import Container from '../../Components/Container/Container';
import { useState, useContext, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { Table } from '../../Components/Table/Table';
import { Content, getTrail } from '../../../Model/trail';
import { AuthContext } from '../../../utils/Authentication';
import dayjs from 'dayjs';

interface TrailData extends Content {
  subRows?: TrailData[];
}

const Trail = () => {
  const auth = useContext(AuthContext);
  const [error, setError] = useState('');
  const [data, setData] = useState<Content[]>([]);

  const columns = [
    {
      Header: 'Customer',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Message',
          accessor: 'message',
        },
        {
          Header: 'Path',
          accessor: 'path',
        },
        {
          Header: 'Method',
          accessor: 'method',
        },
        {
          Header: 'Response',
          accessor: 'response',
        },
        {
          Header: 'Server',
          accessor: 'server',
        },
        {
          Header: 'Client',
          accessor: 'client',
        },
        {
          Header: 'Created At',
          accessor: 'created_at',
          Cell: ({ row }: any) => {
            if (row.original.created_at === undefined) {
              return '';
            }
            return dayjs(row.original.created_at).format('DD/MM/YYYY HH:mm:ss');
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchTrail = async () => {
      const result: any = await getTrail(auth.auth.data.token);
      console.log(result.body.content);
      if (result.status === true) {
        setData(result.body.content);
      } else {
        setError(result.body.message);
      }
    };
    fetchTrail();
  }, [auth]);

  return (
    <>
      <Container title={'Audit Trail'}>
        {error !== '' ? (
          <Alert className='mt-3' variant='warning'>
            {error}
          </Alert>
        ) : (
          <>
            <Table<TrailData>
              name={'trailTable'}
              columns={columns}
              data={data}
            />
          </>
        )}{' '}
      </Container>
    </>
  );
};

export default Trail;
