import styled from 'styled-components';

export const IconContainer = styled.div`
  ${this}:hover {
    cursor: pointer;
  }
`;

export const LegitButton = styled.button`
  background-color: #00a654;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 8px 14px;
  font-weight: 500;

  ${this}:hover {
    cursor: pointer;
    background-color: #5a8c5c;
  }

  ${this}:disabled {
    cursor: default;
    text-decoration: none;
    color: white;
    background-color: #cecece;
  }
`;

export const FraudButton = styled.button`
  background-color: #f55656;
  border-radius: 4px;
  color: white;
  border: none;
  padding: 8px 14px;
  font-weight: 500;

  ${this}:hover {
    cursor: pointer;
    background-color: #a32e2e;
  }

  ${this}:disabled {
    cursor: default;
    text-decoration: none;
    color: white;
    background-color: #cecece;
  }
`;

export const QuestionTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  color: #565656;
`;

export const LabelInfo = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #6b6767;
`;

export const TrueNumber = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #4caf50;
  /* text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25); */
`;

export const FalseNumber = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #f55656;
  /* text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25); */
`;

export const MissedNumber = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  color: #6b6767;
  /* text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25); */
`;

export const MoreQuestion = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  color: #005fd2;
  background-color: transparent;
  border: none;

  ${this}:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  ${this}:disabled {
    cursor: default;
    text-decoration: none;
    color: #cecece;
  }
`;
