import React, { useEffect } from 'react';
import Modal from 'react-modal';

const customStyles: any = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '30%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(71, 71, 71, 0.75)',
  },
};

export interface ReactModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  actionLabel: string;
  actionColor: string;
  resetAll: () => void;
}

export const ReactModal = ({ ...config }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
    config.resetAll();
  }

  useEffect(() => {
    setIsOpen(config.isOpen);
  }, [config]);

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <h5>{config.title}</h5>
        <hr />
        {config.children}
        <div className='d-flex justify-content-between mt-3'>
          <button onClick={closeModal} className='btn btn-light'>
            Cancel
          </button>
          <button
            onClick={() => {
              if (config.actionLabel === 'Add') {
                config.onClickAdd();
              } else {
                config.onClickEdit();
              }
              closeModal();
            }}
            className='btn'
            style={{ backgroundColor: config.actionColor }}
          >
            {config.actionLabel}
          </button>
        </div>
      </Modal>
    </div>
  );
};
