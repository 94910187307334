import { QuestionInterface } from './../Model/question';
import { UserData } from './Authentication';

const LOCAL_AUTH_KEY = 'AUTH';
const LOCAL_CALL_KEY = 'CALL';
const LOCAL_QUESTION_KEY = 'QUESTION';

export function saveCredential(authentication: UserData): void {
  localStorage.setItem(LOCAL_AUTH_KEY, JSON.stringify(authentication));
}

export function getCredential(): UserData {
  const authJSON = localStorage.getItem(LOCAL_AUTH_KEY);
  if (!authJSON)
    return {
      isAuth: false,
      data: {
        username: '',
        token: '',
      },
    };
  const auth = JSON.parse(authJSON);
  return auth;
}

export function clearCredential(): void {
  clearCall();
  clearQuestion();
  localStorage.removeItem(LOCAL_AUTH_KEY);
}

export function saveCall(id: string): void {
  localStorage.setItem(LOCAL_CALL_KEY, id);
}

export function getCall(): string | null {
  const data = localStorage.getItem(LOCAL_CALL_KEY);
  return data;
}

export function clearCall(): void {
  localStorage.removeItem(LOCAL_CALL_KEY);
}

export function saveQuestions(questions: QuestionInterface[]): void {
  localStorage.setItem(LOCAL_QUESTION_KEY, JSON.stringify(questions));
}

export function getQuestions(): QuestionInterface[] | null {
  const questions = localStorage.getItem(LOCAL_QUESTION_KEY);
  if (questions === null) {
    return questions;
  }
  const data: QuestionInterface[] = JSON.parse(questions);
  return data;
}

export function clearQuestion(): void {
  localStorage.removeItem(LOCAL_QUESTION_KEY);
}

export function clearAllSession(): void {
  clearCall();
  clearQuestion();
}
