import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { convertHMS } from '../../../utils/utility';
import Matched from '../../../Assets/Matched.svg';
import NotMatched from '../../../Assets/NotMatched.svg';
import NotEnough from '../../../Assets/NotEnough.svg';
import { ReactSVG } from 'react-svg';
import {
  InformationCardContainer,
  EnrollmentButton,
} from './InformationCardStyle';
import { CRMStateInterface } from '../../Pages/Entry/Entry';
import PuffLoader from 'react-spinners/PuffLoader';

interface InformationCardProps {
  verified?: null | boolean;
  score: number;
  duration: number;
  minVoicedDuration: number;
  isVerifying: boolean;
  setModalStatus: React.Dispatch<React.SetStateAction<boolean>>;
  isAlreadySubmit: boolean;
  crmState: CRMStateInterface;
}

const InformationCard = ({
  verified,
  score,
  duration,
  minVoicedDuration,
  isVerifying,
  setModalStatus,
  isAlreadySubmit,
  crmState,
}: InformationCardProps) => {
  const [clientVoiceDuration, setClientVoiceDuration] = useState(0);
  const [callDuration, setCallDuration] = useState(duration);

  useEffect(() => {
    if (crmState.socket !== null) {
      crmState.socket.addEventListener('message', (evt) => {
        const data = JSON.parse(evt.data);
        const code = data.code;
        if (code === 112) {
          setClientVoiceDuration(data.data.voiced);
        }
      });
    }
  }, [crmState]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCallDuration(callDuration + 1);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [callDuration]);

  console.log(
    {
      verified,
      score,
      duration,
      minVoicedDuration,
      isVerifying,
      setModalStatus,
      isAlreadySubmit,
      crmState,
    },
    'state'
  );

  return (
    <>
      <Row className='text-center'>
        <Col md={6}>
          <InformationCardContainer>
            <div
              className='d-flex flex-column justify-content-between'
              style={{ height: '100%', paddingBottom: '0.25rem' }}
            >
              <h4>Call Duration</h4>
              <div>
                <span className='text-primary'>
                  {' '}
                  {callDuration !== undefined
                    ? convertHMS(Math.round(callDuration))
                    : '0'}{' '}
                </span>{' '}
                in Total <br />
                <span className='text-primary'>{clientVoiceDuration} s </span>
                Client`&apos;`s Voice Only <br /> (Required {
                  minVoicedDuration
                }{' '}
                s for enrollment)
              </div>
              <div className='d-flex justify-content-center'>
                <EnrollmentButton
                  disabled={
                    clientVoiceDuration <= minVoicedDuration || isAlreadySubmit
                  }
                  onClick={() => {
                    setModalStatus(true);
                  }}
                >
                  {isAlreadySubmit ? 'Enrolled' : 'Enroll'}
                </EnrollmentButton>
              </div>
            </div>
          </InformationCardContainer>
        </Col>

        <Col md={6}>
          <InformationCardContainer isWarning={verified === null}>
            <h4>Voice Biometric</h4>
            <div style={{ height: '5rem' }}>
              {isVerifying === true && verified === undefined ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 8,
                  }}
                >
                  <PuffLoader />
                  <p>Verifying</p>
                </div>
              ) : verified === null ? (
                <div>
                  <ReactSVG src={NotEnough} />
                </div>
              ) : verified === true ? (
                <div>
                  <ReactSVG src={Matched} />
                </div>
              ) : (
                <div>
                  <ReactSVG src={NotMatched} />
                </div>
              )}
            </div>
            <br />
            <div>
              {verified === null ? (
                'The Voice is not enough'
              ) : (
                <>
                  Score{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {score !== null ? score.toFixed(2) : '0.00'}
                  </span>{' '}
                </>
              )}
            </div>
          </InformationCardContainer>
        </Col>
      </Row>
    </>
  );
};

export default InformationCard;
