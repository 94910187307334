import React, { useEffect, useState } from 'react';
import ContainerCRM from '../../Components/ContainerCRM/ContainerCRM';
import { ReactSVG } from 'react-svg';
import TimeIcon from '../../../Assets/Time.svg';
import {
  TimeContainer,
  TimeCircle,
  Wrapper,
  CardWaiting,
  CounterStyle,
  WaitingStyle,
  FirstGreeting,
  SecondGreeting,
  Waiting,
  LandingCustomImage,
} from './LandingStyle';
import WaitingCallIcon from '../../../Assets/WaitingCall.svg';
import Pop from '../../../Assets/pop.png';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { AgentInterface } from '../../../Model/agent';
import { convertHMS } from '../../../utils/utility';

interface LeftContentProps {
  name: string;
}

const LeftContent = ({ name }: LeftContentProps) => {
  return (
    <>
      <div>
        <FirstGreeting>Halo, </FirstGreeting>
        <SecondGreeting>{name}</SecondGreeting>
      </div>
      <Waiting className='mt-2'>Stand by for a call</Waiting>
      <div className='d-flex mt-3'>
        <FiberManualRecordIcon style={{ color: '#FBDBCE' }} className='mr-2' />
        <FiberManualRecordIcon style={{ color: '#FBDBCE' }} className='mr-2' />
        <FiberManualRecordIcon style={{ color: '#FBDBCE' }} />
      </div>
      <div className='d-flex justify-content-center'>
        <LandingCustomImage src={Pop} alt='pop-img' className='img-fluid' />
      </div>
    </>
  );
};

interface LandingProps {
  agent: AgentInterface;
}

const Landing = ({ agent }: LandingProps) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const counterInterval = setInterval(function () {
      setCounter(counter + 1);
    }, 1000);

    return () => {
      clearInterval(counterInterval);
    };
  }, [counter]);

  return (
    <ContainerCRM
      leftContent={<LeftContent name={agent.name} />}
      activeTopBar={1}
    >
      <Wrapper>
        <CardWaiting>
          <ReactSVG src={WaitingCallIcon} />
          <CounterStyle>{convertHMS(counter)}</CounterStyle>
          <WaitingStyle>Waiting for a call</WaitingStyle>
        </CardWaiting>
      </Wrapper>
      <TimeContainer>
        <TimeCircle>
          <ReactSVG src={TimeIcon} />
        </TimeCircle>
      </TimeContainer>
    </ContainerCRM>
  );
};

export default Landing;
