import React, { useContext } from 'react';
import { TopItem } from './TopBarStyle';
import { ReactSVG } from 'react-svg';
import WaitingSvg from '../../../Assets/Call.svg';
import InputSvg from '../../../Assets/ID.svg';
import Enroll from '../../../Assets/Enroll.svg';
import { AuthContext } from '../../../utils/Authentication';
import { useHistory } from 'react-router-dom';

interface DataItem {
  id: number;
  name: string;
  icon: any;
}

interface TopBarProps {
  activeTopBar: number;
}

const DATA_TOP_ITEM: DataItem[] = [
  {
    id: 1,
    name: 'Waiting For Call',
    icon: WaitingSvg,
  },
  {
    id: 2,
    name: 'Input ID',
    icon: InputSvg,
  },
  {
    id: 3,
    name: 'On-Call',
    icon: Enroll,
  },
];

const Item = ({ id, name, icon, active }: any) => {
  let style = {};
  if (id === active) {
    style = {
      borderBottom: '4px solid #686868',
    };
  }
  return (
    <TopItem className='mr-5 d-flex align-items-center' style={style}>
      <ReactSVG src={icon} className='mr-2' /> {name}
    </TopItem>
  );
};

const TopBar = ({ activeTopBar }: TopBarProps) => {
  const authentication = useContext(AuthContext);
  const history = useHistory();
  return (
    <div className='d-flex justify-content-center border-light shadow-sm'>
      {DATA_TOP_ITEM.map((data) => {
        return (
          <Item
            key={data.id}
            id={data.id}
            name={data.name}
            icon={data.icon}
            active={activeTopBar}
          />
        );
      })}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          position: 'absolute',
          height: '4rem',
          width: '100%',
          marginRight: '4rem',
        }}
      >
        <button
          onClick={() => {
            authentication.func.changeAuthToFalse();
            history.push('/');
          }}
          className='btn btn-danger'
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default TopBar;
