import styled from 'styled-components';

export const EditIcon: any = styled.div`
  color: #ffb448;
  margin-right: 0.5rem;

  ${this}:hover {
    cursor: pointer;
    background-color: #cecece;
  }
`;

export const DeleteIcon: any = styled.div`
  color: #ff6961;

  ${this}:hover {
    cursor: pointer;
    background-color: #cecece;
  }
`;
