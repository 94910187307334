import Container from '../../Components/Container/Container';
import dayjs from 'dayjs';
import { useState, useContext, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { Table } from '../../Components/Table/Table';
import { CallInterface, getAllCalls } from '../../../Model/call';
import { AuthContext } from '../../../utils/Authentication';

interface CallData extends CallInterface {
  subRows?: CallData[];
}

const Call = () => {
  const authentication = useContext(AuthContext);
  const { auth } = authentication;
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const columns = [
    {
      Header: 'Agent',
      columns: [
        {
          Header: 'No',
          Cell: ({ row }: any) => {
            return row.index + 1;
          },
        },
        {
          Header: 'ID',
          accessor: 'id',
        },
        {
          Header: 'Customer ID',
          accessor: 'customer_id',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
        {
          Header: 'Updated At',
          accessor: 'updated_at',
          Cell: ({ row }: any) => {
            if (row.original.updated_at === undefined) {
              return '';
            }
            return dayjs(row.original.updated_at).format('DD/MM/YYYY HH:mm:ss');
          },
        },
        {
          Header: 'Created At',
          accessor: 'created_at',
          Cell: ({ row }: any) => {
            if (row.original.created_at === undefined) {
              return '';
            }
            return dayjs(row.original.created_at).format('DD/MM/YYYY HH:mm:ss');
          },
        },
      ],
    },
  ].flatMap((c: any) => c.columns);

  useEffect(() => {
    const fetchCallHooks = async () => {
      const result: any = await getAllCalls(auth.data.token);
      if (result.status === true) {
        setData(result.body);
      } else {
        setError(result.body.message);
      }
    };
    fetchCallHooks();
  }, [auth]);

  return (
    <Container title={'Call'}>
      {error !== '' ? (
        <Alert className='mt-3' variant='warning'>
          {error}
        </Alert>
      ) : (
        <>
          <Table<CallData> name={'agentTable'} columns={columns} data={data} />
        </>
      )}
    </Container>
  );
};

export default Call;
