import React, { useContext } from 'react';
import { AuthContext } from '../../../utils/Authentication';
import { NavLink, withRouter } from 'react-router-dom';
import {
  SidebarContainer,
  SidebarContent,
  SidebarIconUser,
  SidebarUser,
} from './SidebarStyle';
import { Row, Col } from 'react-bootstrap';
import {
  Home,
  Person,
  FiberManualRecord,
  ExitToApp,
  Group,
  SupervisorAccount,
  PostAdd,
  History,
  Help,
} from '@material-ui/icons';
import PhoneIcon from '@material-ui/icons/Phone';

interface Menu {
  name: string;
  icon: any;
  route: string;
}

const DATA_MENU: Menu[] = [
  {
    name: 'Home',
    icon: <Home />,
    route: '/admin/home',
  },
  {
    name: 'Customer',
    icon: <Person />,
    route: '/admin/customer',
  },
  {
    name: 'User',
    icon: <Group />,
    route: '/admin/user',
  },
  {
    name: 'Agent',
    icon: <SupervisorAccount />,
    route: '/admin/agent',
  },
  {
    name: 'Call',
    icon: <PhoneIcon />,
    route: '/admin/call',
  },
  {
    name: 'List Questions',
    icon: <PostAdd />,
    route: '/admin/template',
  },
  {
    name: 'Log Questions',
    icon: <Help />,
    route: '/admin/question',
  },
  {
    name: 'Audit Trail',
    icon: <History />,
    route: '/admin/trail',
  },
];

const MenuContent = ({ name, icon, route }: Menu) => {
  const pathName = window.location.pathname;
  let style = {};
  if (pathName === route) {
    style = {
      backgroundColor: 'white',
      color: '#242424',
    };
  }

  return (
    <NavLink style={{ textDecoration: 'none' }} to={route}>
      <SidebarContent style={style} className='d-flex align-items-center'>
        <div style={{ width: '30px' }} className='d-flex align-items-center'>
          {icon}
        </div>
        {name}
      </SidebarContent>
    </NavLink>
  );
};

const SideBar = ({ history }: any) => {
  const authentication = useContext(AuthContext);
  const onClickLogout = () => {
    const { func } = authentication;
    func.changeAuthToFalse();
    history.push('/');
  };

  return (
    <SidebarContainer>
      <SidebarUser>
        <Row>
          <Col xs={3}>
            <SidebarIconUser>{'A'.charAt(0).toUpperCase()}</SidebarIconUser>
          </Col>
          <Col xs={9}>
            <Row>
              <Col xs={12}>
                <div className='text-white'>Admin</div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FiberManualRecord
                  style={{ color: '#68cdbf', marginRight: '5px' }}
                />
                Online
              </Col>
            </Row>
          </Col>
        </Row>
      </SidebarUser>
      <hr className='border-white' />
      {DATA_MENU.map((data, index) => {
        return (
          <MenuContent
            key={index}
            name={data.name}
            icon={data.icon}
            route={data.route}
          ></MenuContent>
        );
      })}
      <hr className='border-white' />
      <SidebarContent
        className='d-flex align-items-center'
        onClick={onClickLogout}
      >
        <div style={{ width: '30px' }} className='d-flex align-items-center'>
          <ExitToApp />
        </div>
        Logout
      </SidebarContent>
      <hr className='border-white' />
    </SidebarContainer>
  );
};

export default withRouter(SideBar);
